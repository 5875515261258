import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ThemeValue } from "@/theme";
import { getIdentity } from "@/apis/im";
import _ from "lodash";

type IdentityItem = {
    faceURL: string,
    identity: number,
    nickname: string,
    onlineStatus: number,
    userID: string
};
const initialState = {
    lng: "en-US",
    originToken: "",
    chatToken: "",
    imToken: "",
    user: {
        userID: "",
        nickname: "",
        faceURL: "",
        createTime: 0,
        ex: "",
        attachedInfo: "",
        globalRecvMsgOpt: 0,
        vipLevel: 0,
        myLike: false,
        likeCount: 0,
        thirdID: ""
    },
    loginModalVisible: false,
    editorModalVisible: false,
    twiceVisible: false,
    loading: false,
    kickVisible: false,
    expiredVisible: false,
    full: false,
    identityList: [] as IdentityItem[],
    theme: {
        name: "light",
        value: {}
    } as ThemeValue
};
export const fetchIdentity = createAsyncThunk("common/getIdentity", async (payload, { dispatch }) => {
    const res = await getIdentity();
    const list = _.compact(res.data || []);
    dispatch(setIdentityList(list));
});
export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setCommonUser(state, action) {
            state.user = action.payload;
        },
        updateCommonUser(state, action) {
            state.user = {
                ...state.user,
                ...action.payload
            };
        },
        setLoginModalVisible(state, action) {
            state.loginModalVisible = action.payload;
        },
        setEditorModalVisible(state, action) {
            state.editorModalVisible = action.payload;
        },
        setTwiceVisible(state, action) {
            state.twiceVisible = action.payload;
        },
        setKickVisible(state, action) {
            state.kickVisible = action.payload;
        },
        setExpiredVisible(state, action) {
            state.expiredVisible = action.payload;
        },
        setThemeValue(state, action) {
            state.theme = action.payload;
        },
        showLoading(state) {
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false;
        },
        setFull(state, action) {
            state.full = action.payload;
        },
        setIdentityList(state, action) {
            state.identityList = action.payload;
        }
    }
});

export const {
    updateCommonUser,
    setLoginModalVisible,
    setEditorModalVisible,
    setTwiceVisible,
    setKickVisible,
    setExpiredVisible,
    showLoading,
    hideLoading,
    setCommonUser,
    setThemeValue,
    setFull,
    setIdentityList
} = commonSlice.actions;
export default commonSlice.reducer;
