import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GroupMemberItem } from "@chat/open-im-sdk";
import { IMSDK } from "@/openIm";
import { getUserFull } from "@/apis/im";
import { getUserLastGame } from "@/apis/app";
import { replaceOssImage } from "@chat/shared";

const NAME = "personal";
type FavoriteGameResListItem = {
    gameId: number;
    winAmount: number;
    gameName: string;
    gameUrl: string;
    gamePicUrl: string
}
type User = {
    thirdID: string;
    vipLevel: number;
    myLike: boolean;
    likeCount: number;
    muted: boolean;
} & GroupMemberItem;
const initialState = {
    user: {
        faceURL: "",
        likeCount: 0,
        myLike: false,
        nickname: "",
        userID: "",
        vipLevel: 0,
        thirdID: ""
    } as User,
    statistics: {
        favoriteGameResList: [] as FavoriteGameResListItem[],
        totalBets: 0,
        totalWagered: 0,
        totalWins: 0,
    },
    loading: true,
    bigVisible: false,
    personVisible: false,
    userId: "",
    groupId: "",
    mutedVisible: false,
    cancelMutedVisible: false
};
const getImUser = async (groupID: string, userID: string) => {
    const res = await IMSDK.getSpecifiedGroupMembersInfo({
        groupID,
        userIDList: [userID]
    });
    return res.data[0];
};
export const getUserByGroupId = createAsyncThunk("personal/getUserByGroupId", async (arg, { getState }) => {
    const state = getState() as any;
    const { userId, groupId } = state.personal as any;
    const imUser = await getImUser(groupId, userId);
    const otherObj = {
        muted: false
    };
    if (imUser) {
        otherObj.muted = imUser.muteEndTime - Date.now() > 0;
    }
    const [appUser] = (await getUserFull({ userId })).data;
    appUser.faceURL = replaceOssImage(appUser.faceURL);
    const user = Object.assign({}, imUser || {}, appUser, otherObj);
    let statistics = {
        favoriteGameResList: [] as FavoriteGameResListItem[],
        totalBets: 0,
        totalWagered: 0,
        totalWins: 0,
    };
    try {
        const res = await getUserLastGame({ imUserId: user.thirdID });
        statistics = res?.data;
    } catch (err) {
        console.log(err);
    }
    return {
        user,
        statistics: statistics
    };
});
export const personalSlice = createSlice({
    initialState,
    name: NAME,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setStatistics(state, action) {
            state.statistics = action.payload;
        },
        setMuted(state, action) {
            state.user.muted = action.payload;
        },
        setUserLikeCount(state, action) {
            state.user.likeCount = action.payload;
        },
        setUserMyLike(state, action) {
            state.user.myLike = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setBigVisible(state, action) {
            state.bigVisible = action.payload;
        },
        closePersonVisible(state) {
            state.personVisible = false;
        },
        openPersonVisible(state, action) {
            const { userId, groupId } = action.payload;
            state.personVisible = true;
            state.userId = userId;
            state.groupId = groupId;
        },
        setMutedVisible(state, action) {
            state.mutedVisible = action.payload;
        },
        setCancelMutedVisible(state, action) {
            state.cancelMutedVisible = action.payload;
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(getUserByGroupId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserByGroupId.fulfilled, (state, action) => {
            const { user, statistics } = action.payload;
            state.user = user;
            state.statistics = statistics;
            state.loading = false;
        });
        builder.addCase(getUserByGroupId.rejected, state => {
            // toast("error", "error");
            state.loading = false;
        });
    }
});

export const {
    setUserLikeCount,
    setUserMyLike,
    setBigVisible,
    setMuted,
    closePersonVisible,
    openPersonVisible,
    setMutedVisible,
    setCancelMutedVisible,
    reset
} = personalSlice.actions;

export default personalSlice.reducer;
