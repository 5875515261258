import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMSDK } from "@/openIm";
import { GroupItem, GroupMemberItem, MessageItem, PublicUserItem } from "@chat/open-im-sdk";
import { toast } from "@/utils";
import { ConversationItem, MessageType } from "@chat/open-im-sdk";
import { getSystemTime, isShowTime } from "@chat/shared";
import { uid } from "uid";
import _ from "lodash";
import { getBalanceList, getPayChannelList } from "@/apis/app";

export const notNeedType: MessageType[] = [
    // MessageType.TypingMessage,
    // MessageType.MemberInvited,
    // MessageType.GroupMemberCancelMuted,
    // MessageType.GroupMemberMuted,
    // MessageType.GroupCreated,
    // MessageType.RevokeMessage
];
type ChannelItem = {
    id: number;
    maxAmount: number;
    minAmount: number;
    payIcon: string;
    payName: string;
    payTag: string
}
type BalanceItem = {
    balance: number;
    giveBalance: number;
    giveScale: number;
    id: number;
    money: number;
    ratio: number;
    sort: number;
    status: number
}

export const enum ConversationType {
    Single = 1,
    Group = 3,
    Notification = 4
}

export type ChatMessageItem = MessageItem & {
    isMergeMessage?: boolean;
    localTime?: string;
    isShowTime?: boolean;
}


const initialState = {
    list: [] as ChatMessageItem[],
    loading: false,
    isEnd: false,
    showVoice: false, // 是否显示音频操作框
    inputMenusVisible: false, // 输入框的底部菜单显示
    currentVoiceClientMsgID: "", // 当前正在播放的id
    chatInfo: {
        groupID: "", // 群聊的id
        userID: "",// 单聊的id
        conversationID: "",
        conversationType: ConversationType.Single,
        currentUserID: "" // 自己的id
    },
    conversationDetail: {} as ConversationItem,
    notReadNum: 0, // 消息未读数量
    // 当前窗口是否是可选状态
    isSelect: false,
    // 当前选中的列表
    selectList: [] as ChatMessageItem[],
    // 当前引用的消息
    quoteMessage: null as ChatMessageItem | null,
    // 当前群信息
    groupInfo: null as GroupItem | null,
    groupOwnerList: [] as GroupMemberItem[],
    singleInfo: {} as PublicUserItem,
    noticeBarVisible: false, // 公告框
    noticeBarModalVisible: false, // 公告框详细
    ruleModalVisible: false, // 规则弹框
    settingVisible: false,
    historyVisible: true,
    // gif图片
    gifImages: [] as { url: string, id: string, tag: string[] }[],
    selfInfo: {} as GroupMemberItem & { muted: boolean }, // 当前用户在群里的信息
    // 当前群的管理员列表
    adminList: [] as GroupMemberItem[],
    dismissVisible: false,
    chatMessage: "",
    showFace: false,
    deleteMessageModalVisible: false,
    // 打开视频
    videoData: {
        visible: false,
        videoElem: {}
    } as MessageItem & { visible: boolean },
    rechargeVisible: false, // 充值界面
    rechargeBalanceList: [] as BalanceItem[],
    rechargeChannel: {} as ChannelItem,
    rechargeValue: ''
};
const createTimeMessage = (time: number) => {
    return {
        localTime: getSystemTime(time),
        contentType: "localTime",
        clientMsgID: uid(),
        sendTime: time
    };
};
const addMergeState = (cur: any, list: any[]) => {
    const len = list.length;
    const message = {
        ...cur,
        isMergeMessage: false
    };
    if (len) {
        const last = list[len - 1];
        const diff = Math.abs((last.sendTime - cur.sendTime) / 1000);
        if (last.contentType === "localTime") {
            return message;
        }
        if (diff < 20 * 60 && last.sendID === cur.sendID) {
            message.isMergeMessage = true;
        }
    }
    return message;
};

// 异步action。 获取历史会话记录
export const fetchHistoryList = createAsyncThunk(
    "chat/fetchHistoryList",
    async (conversationID: string) => {
        const res = await IMSDK.getAdvancedHistoryMessageList({
            lastMinSeq: 0,
            count: 20,
            startClientMsgID: '',
            conversationID,
        });
        const { data } = res;
        // 筛选一些不需要的信息
        const list = data.messageList.filter(item => {
            return !notNeedType.includes(item.contentType);
        });

        // 第二版不需要时间展示 先注释
        let timeList = list.reduce((pre, cur) => {
            const len = pre.length;
            const prevItem = pre[len - 1];
            const showTime = isShowTime(prevItem ? prevItem.sendTime : 0, cur.sendTime);
            if (showTime) {
                pre.push(createTimeMessage(cur.sendTime) as any);
            }
            pre.push(cur);
            return pre;
        }, [] as MessageItem[]);
        if (timeList.length) {
            const preMessage = createTimeMessage(data.messageList[0].sendTime) as any;
            timeList.unshift(preMessage);
        }
        timeList = _.unionBy(timeList, (item: any) => {
            if (item.hasOwnProperty('localTime')) {
                return item.localTime;
            }
            return item.clientMsgID;
        });
        return {
            ...res.data,
            messageList: timeList || []
        };
    }
);
export const fetchRechargeData = createAsyncThunk(
    "chat/fetchRechargeData",
    async (_, { dispatch }) => {
        const IM_TAG = "IM_RECHARGE";
        // 获取im渠道
        getPayChannelList().then(res => {
            if (res.code === 0) {
                const imChannel = res.data.find((item: any) => item.payTag === IM_TAG);
                if (imChannel) {
                    dispatch(setRechargeChannel(imChannel));
                }
            }
        });
        // 获取充值列表
        getBalanceList().then(res => {
            if (res.code === 0) {
                const list = (res.data as BalanceItem[]).sort((a, b) => a.balance - b.balance);
                dispatch(setRechargeBalance(list));
                dispatch(setRechargeValue(list[0]?.balance + ""));
            }
        });
    }
);

// 聊天信息相关
export const chatSlice = createSlice({
    name: "chat" as const,
    initialState,
    reducers: {
        // 添加一条新数据
        addNewMessage(state, action) {
            const list = state.list;
            const prev = list[list.length - 1];
            const cur = action.payload;
            const addList = [];
            const message = addMergeState(cur, list);
            const showTime = isShowTime(prev ? prev.sendTime : 0, cur.sendTime);
            if (showTime) {
                const timeMessage = createTimeMessage(action.payload.sendTime);
                addList.push(timeMessage);
                message.isMergeMessage = false;
            }
            addList.push(message);
            state.list.push(...addList);
            state.notReadNum++;
        },
        resetReadNum(state) {
            state.notReadNum = 0;
        },
        // 更新列表数据
        updateMessage(state, action) {
            const index = state.list.findIndex(item => item.clientMsgID === action.payload.clientMsgID);
            const list = state.list;
            if (~index) {
                list[index] = {
                    ...list[index],
                    ...action.payload
                };
                state.list = list;
            }
        },
        // 更新列表全部已读
        updateAllMessageRead(state) {
            state.list = state.list.map(item => {
                return {
                    ...item,
                    isRead: true
                };
            });
        },
        // 更新状态为已读
        updateMessageRead(state, action) {
            const list = state.list;
            const msgIDList = action.payload;
            msgIDList.forEach((id: string) => {
                const cur = list.find(item => item.clientMsgID === id);
                if (cur) {
                    cur.isRead = true;
                }
            });
        },
        // 更新列表消息里的用户信息
        updateMessageUser(state, action) {
            const data = action.payload;
            const list = state.list.map(item => {
                if (item.sendID === data.userID) {
                    return {
                        ...item,
                        senderNickname: data.nickname,
                        senderFaceUrl: data.faceURL
                    };
                }
                return item;
            });
            state.list = list;
        },
        // 更新当前的聊天窗口信息
        setChatInfo(state, action) {
            state.chatInfo = {
                ...state.chatInfo,
                ...action.payload
            };
        },
        // 设置当前的群信息
        updateGroupInfo(state, action) {
            state.groupInfo = action.payload;
            if (action.payload) {
                state.noticeBarVisible = !!action.payload.notification?.trim();
            }
        },
        // 设置当前群用户列表
        setGroupOwnerList(state, action) {
            state.groupOwnerList = action.payload;
        },
        // 更新当前单聊信息
        updateSingleInfo(state, action) {
            state.singleInfo = action.payload;
        },
        // 设置选中状态
        setSelectState(state, action) {
            state.isSelect = action.payload;
            state.selectList = [];
        },
        // 添加选中的消息列表
        addSelectList(state, action) {
            // 判断是否存在的，存在就取消，不存在就添加
            const index = state.selectList.findIndex(item => item.clientMsgID === action.payload.clientMsgID);
            if (~index) {
                state.selectList.splice(index, 1);
                if (state.selectList.length === 0) {
                    state.isSelect = false;
                }
            } else {
                if (state.selectList.length === 5) {
                    toast("Select up to 5 items!", "error");
                    return;
                }
                state.selectList.push(action.payload);
            }

        },
        setQuoteMessage(state, action) {
            state.quoteMessage = action.payload;
        },
        setShowVoice(state, action) {
            state.showVoice = action.payload;
        },
        // 设置当前正在播放的id
        setCurrentVoiceId(state, action) {
            state.currentVoiceClientMsgID = action.payload;
        },
        // 清除信息
        deleteMessageFormStore(state, action) {
            const clientMsgIDs = Array.isArray(action.payload) ? action.payload : [action.payload];
            _.remove(state.list, item => clientMsgIDs.includes(item.clientMsgID));
        },
        setRuleModalVisible(state, action: { payload: boolean }) {
            state.ruleModalVisible = action.payload;
        },
        setNoticeBarModalVisible(state, action: { payload: boolean }) {
            state.noticeBarModalVisible = action.payload;
        },
        setNoticeBarVisible(state, action: { payload: boolean }) {
            state.noticeBarVisible = action.payload;
        },
        // 群设置弹框显示
        setSettingVisible(state, action: { payload: boolean }) {
            state.settingVisible = action.payload;
        },
        setGifImages(state, action) {
            state.gifImages = action.payload;
        },
        setSelfInfo(state, action) {
            const muted = action.payload.muteEndTime > Date.now();
            state.selfInfo = {
                ...action.payload,
                muted
            };
        },
        setSelfMuted(state, action) {
            state.selfInfo = {
                ...state.selfInfo,
                muted: action.payload
            };
        },
        setHistoryVisible(state, action) {
            state.historyVisible = action.payload;
        },
        setAdminList(state, action) {
            state.adminList = action.payload;
        },
        showDismissVisible(state) {
            state.dismissVisible = true;
        },
        hiddenDismissVisible(state) {
            state.dismissVisible = false;
        },
        setChatMessage(state, action) {
            // 不要去除首尾空格，textArea会丢失换行！
            state.chatMessage = action.payload;
        },
        openFace(state) {
            state.showFace = true;
        },
        closeFace(state) {
            state.showFace = false;
        },
        setShowFace(state, action) {
            state.showFace = action.payload;
        },
        deleteMessageModalShow: (state) => {
            state.deleteMessageModalVisible = true;
        },
        deleteMessageModalHidden: (state) => {
            state.deleteMessageModalVisible = false;
        },
        setConversationDetail(state, action) {
            state.conversationDetail = action.payload;
        },
        setInputMenusVisible(state, action) {
            state.inputMenusVisible = action.payload;
        },
        showVideoData(state, action) {
            state.videoData = {
                visible: true,
                ...action.payload
            };
        },
        hiddenVideo(state) {
            state.videoData = {
                ...state.videoData,
                visible: false
            };
        },
        setRechargeVisible(state, action) {
            state.rechargeVisible = action.payload;
        },
        setRechargeBalance(state, action) {
            state.rechargeBalanceList = action.payload;
        },
        setRechargeChannel(state, action) {
            state.rechargeChannel = action.payload;
        },
        setRechargeValue(state, action) {
            state.rechargeValue = action.payload;
        },
        resetChat: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHistoryList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchHistoryList.fulfilled, (state, { payload }) => {
            const { messageList } = payload;
            const list = messageList.reduce((prev, cur) => {
                const message = addMergeState(cur, prev);
                prev.push(message);
                return prev;
            }, [] as ChatMessageItem[]);
            state.loading = false;
            state.list.push(...list);
            // setTimeout(() => {
            //     emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
            // }, 20);
        });
    }
});
export const {
    addNewMessage,
    updateMessage,
    updateAllMessageRead,
    updateMessageRead,
    updateGroupInfo,
    updateSingleInfo,
    setChatInfo,
    setSelectState,
    setCurrentVoiceId,
    setShowVoice,
    setSettingVisible,
    updateMessageUser,
    resetChat,
    addSelectList,
    setQuoteMessage,
    deleteMessageFormStore,
    setRuleModalVisible,
    setNoticeBarModalVisible,
    setNoticeBarVisible,
    setHistoryVisible,
    setGroupOwnerList,
    setGifImages,
    setSelfInfo,
    setSelfMuted,
    setAdminList,
    showDismissVisible,
    hiddenDismissVisible,
    openFace,
    closeFace,
    setShowFace,
    setChatMessage,
    deleteMessageModalShow,
    deleteMessageModalHidden,
    resetReadNum,
    setConversationDetail,
    setInputMenusVisible,
    setRechargeVisible,
    setRechargeValue,
    setRechargeBalance,
    setRechargeChannel,
    showVideoData,
    hiddenVideo
} = chatSlice.actions;

export default chatSlice.reducer;
