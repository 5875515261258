import { createSlice } from "@reduxjs/toolkit";
import type { GroupItem, GroupMemberItem, ConversationItem } from "@chat/open-im-sdk";

const initialState = {
    groupDetail: null as GroupItem | null,
    memberList: [] as GroupMemberItem[],
    conversationDetail: {} as ConversationItem,
    scroll: {
        top: 0,
        left: 0
    }
};
export const groupSetting = createSlice({
    name: "groupSetting",
    initialState,
    reducers: {
        updateGroupDetail: (state, action) => {
            state.groupDetail = action.payload;
        },
        updateGroupMember: (state, action) => {
            state.memberList = action.payload;
        },
        updateConversation: (state, action) => {
            state.conversationDetail = action.payload;
        },
        updateScroll: (state, action) => {
            state.scroll = action.payload;
        }
    }
});
export const {
    updateGroupDetail,
    updateGroupMember,
    updateConversation,
    updateScroll
} = groupSetting.actions;

export default groupSetting.reducer;
