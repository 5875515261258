import {
    CbEvents,
    GroupItem,
    GroupMemberItem,
    MessageItem,
    MessageReceiveOptType,
    OpenIMSDK,
    ReceiptInfo,
    SelfUserInfo,
    SessionType,
    WsResponse
} from "@chat/open-im-sdk";
import store2 from "store2";
import store from "@/store";
import { GetOneConversationParams, SendMsgParams } from "@chat/open-im-sdk";
import type { ConversationItem } from "@chat/open-im-sdk";
import {
    addNewMessage,
    deleteMessageFormStore,
    notNeedType, setConversationDetail,
    setSelfInfo,
    updateGroupInfo,
    updateMessage,
    updateMessageRead,
    updateMessageUser
} from "@/store/slice/chat";
import { addConversationList, setTotalUnread, updateConversationList } from "@/store/slice/home";
import { setCommonUser, setExpiredVisible, setKickVisible, updateCommonUser } from "@/store/slice/common";
import { uid } from "uid";
import { emitter, sendUnreadChange, toast } from "@/utils";
import { deleteForEveryone, getUserFull } from "@/apis/im";
import { getImageInfo, getlatestMsg } from "@chat/shared";

export const IMSDK = new OpenIMSDK();
type OpenImOptions = {
    afterLogin?: (userInfo: LoginTypeParams | null) => void;
    store: typeof store;
    originToken?: string;
}
type LoginTypeParams = {
    originToken: string;
    imToken: string;
    userID: string;
    chatToken: string;
} | null | undefined;

export type ChatMessageItem = MessageItem & {
    isMergeMessage?: boolean;
    localTime?: string;
    isShowTime?: boolean;
}

class OpenIm {
    IMSDK = IMSDK;
    afterLogin?: (userInfo: LoginTypeParams | null) => void;
    store!: typeof store;

    constructor(options: OpenImOptions) {
        this.init(options);
    }

    init(options: OpenImOptions) {
        this.afterLogin = options.afterLogin;
        this.store = options.store;
        // this.login();
    }

    getUserInfo(userID: string) {
        Promise.all([this.IMSDK.getSelfUserInfo(), getUserFull({ userId: userID })]).then(res => {
            const [imInfo, appInfo] = res;
            const obj = {
                ...imInfo.data,
                ...(appInfo.data[0] || {})
            };
            store.dispatch(setCommonUser(obj));
        });
    }

    async login(data: LoginTypeParams) {
        if (!data) {
            this.afterLogin?.(null);
            return;
        }
        const { imToken, userID } = data;
        store2.setAll(data);
        try {
            await this.IMSDK.login({
                userID,       // IM 用户 userID
                token: imToken,        // IM 用户令牌
                platformID: 5,   // 当前登录平台号
                apiAddr: process.env.REACT_APP_IM_API_URL!,   // IM api 地址
                wsAddr: process.env.REACT_APP_IM_WS_URL!   // IM ws 地址
            });
            this.afterLogin?.(data);
        } catch (err) {
            console.error("err", err);
        }

    }

    // 判断是否在当前会话中
    inCurrentConversation = (newMsg: MessageItem) => {
        const chat = this.store.getState().chat;
        switch (newMsg.sessionType) {
            case SessionType.Single:
                return newMsg.sendID === chat.chatInfo.userID || newMsg.sendID === chat.chatInfo.currentUserID && newMsg.recvID === chat.chatInfo.userID;
            case SessionType.Group:
                return chat.chatInfo.groupID === newMsg.groupID;
            default:
                return false;
        }
    };

    // 接受信息回调
    newMessageHandler = ({ data }: WsResponse<MessageItem | MessageItem[]>) => {
        const list = Array.isArray(data) ? data : [data];
        const chat = this.store.getState().chat;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            // 群聊
            if (item.sessionType === SessionType.Group) {

            }
            if (!notNeedType.includes(item.contentType)) {
                // 判断是否是当前群
                if (this.inCurrentConversation(item)) {
                    // 判断是否存在当前
                    const hasMsg = chat.list.find(chatItem => chatItem.clientMsgID === item.clientMsgID);
                    if (!hasMsg) {
                        this.store.dispatch(addNewMessage(item));
                    }
                }
            }
        }
        Promise.resolve().then(() => {
            // 判断当前是不是在最后
            emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
        });
    };
    // 会话总未读数发生变化
    totalUnreadChangeHandler = ({ data }: WsResponse<number>) => {
        const { list } = this.store.getState().home;
        const unreadCount = list.reduce((total, item) => {
            if (item.conversationID) {
                return total + item.unreadCount;
            }
            return total;
        }, 0);
        this.store.dispatch(setTotalUnread(data));
        sendUnreadChange(unreadCount);
    };
    // 会话信息发生变化
    conversationChangedHandler = ({ data }: WsResponse<ConversationItem[]>) => {
        const { conversationDetail } = this.store.getState().chat;
        data.forEach(item => {
            // 查找所有的会话 更新会话
            this.store.dispatch(updateConversationList(item));
            // 判断当前会话是否需要更新
            if (conversationDetail?.conversationID === item.conversationID) {
                this.store.dispatch(setConversationDetail(item));
            }
            // 只能通过其来获取seq的数据
            const latestMsg = getlatestMsg(item.latestMsg);
            if (latestMsg) {
                // 后期想起来在做吧
                this.store.dispatch(updateMessage(latestMsg));
            }
        });
    };
    // 产生新的会话
    newConversationHandler = ({ data }: WsResponse<ConversationItem[]>) => {
        const homeState = this.store.getState().home;
        if (!Array.isArray(data)) {
            data = [data];
        }
        // 如果已经存在，那么不增加
        const addList = data.filter((newItem: any) => {
            const curIndex = homeState.list.findIndex((item: any) => newItem.conversationID === item.conversationID);
            return !~curIndex;
        });
        // 新增
        if (addList.length) {
            // 需要判断是否是客服
            this.store.dispatch(addConversationList(addList));
        }
        if (addList.length) {
            emitter.emit("newConversation", addList);
        }
    };
    selfInfoUpdateHandler = ({ data }: WsResponse<SelfUserInfo>) => {
        this.store.dispatch(updateCommonUser(data));
    };
    newRecvMessageRevokedHandler = ({ data }: WsResponse<string>) => {
        // console.log("撤回", data);
    };
    // 单聊回执
    recvC2CReadReceiptHandler = ({ data }: WsResponse<ReceiptInfo[]>) => {
        // 判断是否已读，如果是的话，更新全部已读
        const conversationDetail = this.store.getState().chat.conversationDetail;
        data.forEach(item => {
            const { userID, msgIDList } = item;
            if (conversationDetail.userID === userID) {
                this.store.dispatch(updateMessageRead(msgIDList));
            }
        });
    };
    msgDeletedHandler = ({ data }: WsResponse<any>) => {
        this.store.dispatch(deleteMessageFormStore(data.clientMsgID));
    };
    syncServerFailedHandler = () => {
        toast("Sync failed!", "error");
    };
    groupInfoChanged = ({ data }: WsResponse<GroupItem>) => {
        const chat = this.store.getState().chat;
        if (chat.chatInfo.groupID === data.groupID) {
            this.store.dispatch(updateGroupInfo(data));
        }
    };
    kickedOfflineHandler = () => {
        this.store.dispatch(setKickVisible(true));
    };
    userTokenExpiredHandler = () => {
        this.store.dispatch(setExpiredVisible(true));
    };
    groupMemberInfoChanged = ({ data }: WsResponse<GroupMemberItem>) => {
        // 将当前sendID，循环列表，修改这个群成员的聊天信息
        // 判断是否是自己
        if (data.userID === store2.get("userID")) {
            this.store.dispatch(setSelfInfo(data));
        }
        this.store.dispatch(updateMessageUser(data));
    };
    deleteConversation = async (conversationID: string) => {
        try {
            const res = await this.IMSDK.deleteConversationAndDeleteAllMsg(conversationID);
            return res.data;
        } catch (err) {
            console.error(err);
        }
    };
    connectFailed = (data: any) => {
        console.log(data, '掉线');

    };
    systemListener = () => {
        this.IMSDK.on(CbEvents.OnTotalUnreadMessageCountChanged, this.totalUnreadChangeHandler);
        this.IMSDK.on(CbEvents.OnKickedOffline, this.kickedOfflineHandler);
        this.IMSDK.on(CbEvents.OnUserTokenExpired, this.userTokenExpiredHandler);
        this.IMSDK.on(CbEvents.OnSyncServerFailed, this.syncServerFailedHandler);
        this.IMSDK.on(CbEvents.OnSelfInfoUpdated, this.selfInfoUpdateHandler);
        this.IMSDK.on(CbEvents.OnConnectFailed, this.connectFailed);
    };
    removeSystemListener = () => {
        this.IMSDK.off(CbEvents.OnTotalUnreadMessageCountChanged, this.totalUnreadChangeHandler);
        this.IMSDK.off(CbEvents.OnKickedOffline, this.kickedOfflineHandler);
        this.IMSDK.off(CbEvents.OnUserTokenExpired, this.userTokenExpiredHandler);
        this.IMSDK.off(CbEvents.OnSyncServerFailed, this.syncServerFailedHandler);
        this.IMSDK.off(CbEvents.OnSelfInfoUpdated, this.selfInfoUpdateHandler);
        this.IMSDK.off(CbEvents.OnConnectFailed, this.connectFailed);
    };
    chatListener = () => {
        this.IMSDK.on(CbEvents.OnMsgDeleted, this.msgDeletedHandler);
        // 群消息发生变更
        this.IMSDK.on(CbEvents.OnGroupInfoChanged, this.groupInfoChanged);
        // 群成员发生变更
        this.IMSDK.on(CbEvents.OnGroupMemberInfoChanged, this.groupMemberInfoChanged);

        this.IMSDK.on(CbEvents.OnRecvNewMessages, this.newMessageHandler);
        this.IMSDK.on(CbEvents.OnNewRecvMessageRevoked, this.newRecvMessageRevokedHandler);
        // 单聊的回调
        this.IMSDK.on(CbEvents.OnRecvC2CReadReceipt, this.recvC2CReadReceiptHandler);
        // 群聊的回调
    };
    removeChatListener = () => {
        this.IMSDK.off(CbEvents.OnMsgDeleted, this.msgDeletedHandler);
        this.IMSDK.off(CbEvents.OnGroupInfoChanged, this.groupInfoChanged);
        this.IMSDK.off(CbEvents.OnGroupMemberInfoChanged, this.groupMemberInfoChanged);

        this.IMSDK.off(CbEvents.OnRecvNewMessages, this.newMessageHandler);
        this.IMSDK.off(CbEvents.OnNewRecvMessageRevoked, this.newRecvMessageRevokedHandler);
        this.IMSDK.off(CbEvents.OnRecvC2CReadReceipt, this.recvC2CReadReceiptHandler);
    };
    conversationListener = () => {
        // 关键会话更新
        this.IMSDK.on(CbEvents.OnConversationChanged, this.conversationChangedHandler);
        // 新增会话
        this.IMSDK.on(CbEvents.OnNewConversation, this.newConversationHandler);
    };
    removeConversationListener = () => {
        this.IMSDK.off(CbEvents.OnConversationChanged, this.conversationChangedHandler);
        this.IMSDK.off(CbEvents.OnNewConversation, this.newConversationHandler);
    };

    setImListeners = () => {
        this.systemListener();
        this.chatListener();
        this.conversationListener();
    };
    clearImListeners = () => {
        this.removeSystemListener();
        this.removeChatListener();
        this.removeConversationListener();
    };

    // 标记群聊或者单聊的已读
    async markConversationAsRead(options: {
        conversationID: string
    } & GetOneConversationParams) {
        try {
            const { data } = await IMSDK.getOneConversation(options);
            if (data.unreadCount > 0) {
                await IMSDK.markConversationMessageAsRead(options.conversationID);
            }
            // 成功回调
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 获取指定会话信息
    async getOneConversation(options: GetOneConversationParams) {
        try {
            const { data } = await IMSDK.getOneConversation(options);
            return Promise.resolve(data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 设置当前会话置顶状态
    async setPinConversation(conversationID: string, isPinned: boolean) {
        try {
            await IMSDK.pinConversation({
                conversationID,
                isPinned
            });
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 设置当前会话免打扰 setConversationRecvMessageOpt
    async setRecvMessageOpt(conversationID: string, opt: MessageReceiveOptType) {
        try {
            await IMSDK.setConversationRecvMessageOpt({
                conversationID,
                opt
            });
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 创建文本消息
    async createTextMessage(text: string) {
        return new Promise<MessageItem>((resolve, reject) => {
            this.IMSDK.createTextMessage(text).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    // 创建引用信息
    async createQuoteMessage(QuoteMessage: MessageItem, text: string) {
        const res = await this.IMSDK.createQuoteMessage({
            text,
            message: JSON.stringify(QuoteMessage),
        });
        return res.data;
    }

    // 创建语音信息
    async createVoiceMessage(file: File, duration: number) {
        const type = file.type.split(';')[0].split('/')[1] || 'webm';
        // 上传音频文件
        // const { url } = await this.updateFile(uid() + "." + type, type, file);
        // 通过音频文件创建语音信息
        const obj = {
            uuid: uid(),
            soundPath: "",
            sourceUrl: "",
            // sourceUrl: url,
            dataSize: file.size,
            duration: Math.floor(duration), // 必须传递整数，浮点报错！
            soundType: type,
            file
        };
        const res = await this.IMSDK.createSoundMessageByFile(obj);
        return res.data;
    }

    // 根据file对象创建gif图片信息
    async createImageMessage(originUrl: string) {
        const { width, height, url } = await getImageInfo(originUrl);
        const randomUid = uid();
        const options = {
            type: "image",
            id: randomUid,
            url,
            width,
            height,
        };
        return new Promise<MessageItem>((resolve, reject) => {
            this.IMSDK.createCustomMessage({
                data: JSON.stringify(options),
                extension: "",
                description: ""
            }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    // 删除信息
    async deleteMessage(conversationID: string, list: MessageItem[], isAll = false) {
        try {
            let api = isAll ? this.deleteServerMessage : this.deleteLocalMessage;
            // 删除本地信息和远程信息
            // 找到消息， 清除
            return await api(conversationID, list);
        } catch (err) {
            return Promise.reject(err);
        }

    }

    // 删除指定信息 forme
    deleteLocalMessage = async (conversationID: string, list: MessageItem[]) => {
        try {
            const p = list.map(item => {
                return this.IMSDK.deleteMessage({
                    conversationID,
                    clientMsgID: item.clientMsgID
                });
            });
            await Promise.all(p);
            return list.map(item => item.clientMsgID);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    async deleteServerMessage(conversationID: string, list: MessageItem[]) {
        try {
            const messages = list.map(item => {
                return {
                    seqs: item.seq,
                    sendID: item.sendID
                };
            });
            const res = await deleteForEveryone({
                conversationID,
                messages,
            });
            if (res.status === 0) {
                return list.map(item => item.clientMsgID);
            } else {
                return Promise.reject(res.message);
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // 获取指定群的信息
    async getSpecifiedGroupsInfo(groupId: string) {
        const res = await this.IMSDK.getSpecifiedGroupsInfo([groupId]);
        const [groupInfo] = res.data;
        return groupInfo;
    }

    // 获取群管理员和群主列表
    async getGroupMemberOwnerAndAdmin(groupId: string) {
        try {
            const res = await this.IMSDK.getGroupMemberOwnerAndAdmin(groupId);
            return res.data;
        } catch (err) {
            return [];
        }
    }

    // 禁言或者取消禁言某个群成员
    async muteGroupMember(groupID: string, userID: string, muted: number) {
        const res = await this.IMSDK.changeGroupMemberMute({
            groupID,
            userID,
            mutedSeconds: muted
        });
        return res.data;
    }

    // 获取指定群成员信息
    async getGroupMemberInfo(groupID: string, userID: string) {
        const res = await this.IMSDK.getSpecifiedGroupMembersInfo({
            groupID,
            userIDList: [userID]
        });
        return res.data[0];
    }

    // 获取指定人的信息
    async getUsersInfo(userID: string) {
        const res = await this.IMSDK.getUsersInfoWithCache({
            userIDList: [userID]
        });
        const [singleInfo] = res.data;
        return singleInfo;
    }

    // 获取指定群成员列表
    async getGroupMemberList(groupID: string) {
        try {
            const obj = {
                groupID,
                filter: 0,
                offset: 0,
                count: 5000,
            } as any;
            const res = await this.IMSDK.getGroupMemberList(obj);
            return res.data;
        } catch (err) {
            return [];
        }

    }

    // 发送消息
    async sendMessage(message: SendMsgParams) {
        const user = this.store.getState().common.user;
        // 发送信息携带vip信息
        const ex = {
            vipLevel: user.vipLevel
        };
        message.message.ex = JSON.stringify(ex);
        try {
            const res = await this.IMSDK.sendMessageNotOss(message);
            return res.data;
        } catch (err: any) {
            switch (err.errCode) {
                case 1204:
                    toast("the group has been disbanded", "error");
                    break;
                case 5999:
                    toast(err.errMsg.replace(/5999/ig, "").trim(), "error");
                    break;
            }
            // 发送失败
            return Promise.reject(err);
        }
    }
    // 根据群id或者用户id建立会话, 返回会话id
    async createConversation(options: GetOneConversationParams) {
        try {
            const res = await this.IMSDK.getOneConversation(options);
            if (res.errCode === 0) {
                return res.data;
            }
            return Promise.reject(res);
        } catch (err) {
            console.error('Error getting conversation:', err);
            return Promise.reject(err);
        }
    }

    async getFriendList() {
        const res = await this.IMSDK.getFriendList();
        return res.data;
    }
}

export default OpenIm;

/*
* const openIm = new OpenIm()
* openIm.init()
*
* */
