import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMSDK } from "@/openIm";
import { getIdentity, getPasswordRoom } from "@/apis/im";
import { getConversationList, getVisibleUnReadCount, sendTabBarVisible, sendUnreadChange } from "@/utils";
import type { MessageReceiveOptType, ConversationItem } from "@chat/open-im-sdk";
import _ from "lodash";

type PasswordMessageItem = {
    faceURL: string,
    groupID: string,
    groupType: number,
    showName: string,
    isPassword: boolean,
    isNotInGroup: boolean,
    recvMsgOpt: MessageReceiveOptType
}
export type CustomConversationItem = {
    isPassword: boolean;
    isIdentity?: boolean; //是否是客服
} & ConversationItem;

const initialState = {
    list: [] as CustomConversationItem[],
    loading: true,
    setting: false,
    selectList: [] as CustomConversationItem[],
    totalUnread: 0,
    goChatFirst: false,
    oneChat: {
        conversationID: "",
        groupID: ""
    },
    password: {
        groupID: "",
        visible: false,
        error: false,
        value: "",
        focused: false
    },
    passwordList: [] as PasswordMessageItem[],// 密码房列表
    identityList: [] as string[] // 客服列表
};


// 异步action。 获取历史会话列表
export const fetchConversationList = createAsyncThunk(
    "home/conversationList",
    async (data, { dispatch }) => {
        const [res, passRes, identityRes] = await Promise.all([
            // 提取im的列表
            IMSDK.getConversationListSplit({
                offset: 0,
                count: 200
            }),
            // 提取密码房列表
            getPasswordRoom(),
            // 获取客服列表
            getIdentity()
        ]);
        const imList = res.data as CustomConversationItem[] || [];
        const passGroups = passRes.data.groups.map((item: any) => {
            return {
                ...item,
                isPassword: true,
                isNotInGroup: true,
                recvMsgOpt: 0, // 默认免打扰关闭
            };
        });
        const identityList = _.compact<any>(identityRes.data).map(item => item.userID);
        dispatch(setPasswordList(passGroups));
        dispatch(setIdentityList(identityList));
        return {
            imList
        };
    }
);
// 获取所有的未读数
export const fetchTotalUnread = createAsyncThunk(
    "home/totalUnread",
    async (_, { dispatch }) => {
        const res = await IMSDK.getTotalUnreadMsgCount();
        dispatch(setTotalUnread(res.data));
        return res.data;
    }
);
// 首页会话相关
export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setConversationList(state, action) {
            state.list = action.payload;
        },
        setTotalUnread(state, action) {
            state.totalUnread = action.payload;
        },
        // 更新单条会话数据
        updateConversationList(state, action) {
            const currentIndex = state.list.findIndex(item => item.conversationID === action.payload.conversationID);
            // 最后一个置顶
            if (~currentIndex) {
                const cur = state.list[currentIndex];
                state.list[currentIndex] = {
                    ...cur,
                    ...action.payload
                };
            }
        },
        // 更新会话的顺序
        // 1.更新数据是置顶的，直接排序到最顶
        // 2.更新的数据不是置顶，放在所有置顶最后
        // 3.如果没有置顶，直接放在最顶
        // 新增一个会话记录
        addConversationList(state, action) {
            let data = action.payload;
            const list = Array.isArray(data) ? data : [data];
            state.list.push(...getConversationList(list));
        },
        showSetting(state) {
            state.setting = true;
        },
        hideSetting(state) {
            state.setting = false;
        },
        addSelectItem(state, action) {
            const curIndex = state.selectList.findIndex(item => item.groupID === action.payload.groupID);
            if (~curIndex) {
                state.selectList.splice(curIndex, 1);
                if (state.selectList.length === 0) {
                    state.setting = false;
                }
            } else {
                state.selectList.push(action.payload);
            }
        },
        resetSetting(state) {
            state.setting = false;
            state.selectList = [];
        },
        showPassword(state, action) {
            state.password.groupID = action.payload.groupID;
            state.password.visible = true;
            state.password.focused = true;
            // 显示弹窗，关闭app tabbar
            sendTabBarVisible(false);
        },
        setPasswordError(state, action) {
            state.password.error = action.payload;
        },
        setPasswordValue(state, action) {
            state.password.value = action.payload;
        },
        setPasswordFocused(state, action) {
            state.password.focused = action.payload;
        },
        hidePassword(state) {
            state.password = initialState.password;
            sendTabBarVisible(true);
        },
        setPasswordList(state, action) {
            state.passwordList = action.payload;
        },
        setIdentityList(state, action) {
            state.identityList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConversationList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchConversationList.fulfilled, (state, action) => {
            const { imList } = action.payload;
            let list = [...imList];
            state.loading = false;
            // 判断当前imlist是否存在密码房
            for (let i = 0; i < state.passwordList.length; i++) {
                const passwordCur = state.passwordList[i];
                const imItem = list.find(item => item.groupID === passwordCur.groupID);
                if (imItem) {
                    imItem.isPassword = true;
                } else {
                    list.push(
                        {
                            ...passwordCur,
                            isNotInGroup: true,
                            recvMsgOpt: 0, // 默认免打扰关闭
                            isPassword: true,
                        } as any
                    );
                }
            }
            // 添加客服数据
            list.forEach(item => {
                // 只有单聊
                if (item.userID) {
                    item.isIdentity = state.identityList.includes(item.userID);
                }
            });
            list = _.orderBy(list, ['isIdentity', "isPinned"], ['asc', 'desc']);
            const prevList = [];
            const nextList = [];
            for (let i = 0; i < list.length; i++) {
                const cur = list[i];
                if (cur.conversationID) {
                    prevList.push(cur);
                } else {
                    nextList.push(cur);
                }
            }
            // 客服永远置顶
            const newList = getConversationList([...prevList, ...nextList]);
            state.list = newList;
            // 获取可见会话的未读数量
            const totalUnread = getVisibleUnReadCount(newList);
            sendUnreadChange(totalUnread);
        });
        builder.addCase(fetchConversationList.rejected, state => {
            state.loading = false;
        });
    }
});
export const {
    setConversationList,
    setTotalUnread,
    updateConversationList,
    addConversationList,
    showSetting,
    hideSetting,
    resetSetting,
    addSelectItem,
    showPassword,
    hidePassword,
    setPasswordValue,
    setPasswordFocused,
    setPasswordError,
    setPasswordList,
    setIdentityList
} = homeSlice.actions;


export default homeSlice.reducer;
